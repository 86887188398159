@import "styles/mixins.scss";

:global(body:not(.fullwidth)) {
  :local(.main) {
    flex-grow: 1;
    max-width: 1150px;
    margin: 0 auto;
    @include container();
    padding-top: 24px;
    padding-bottom: 24px;
  }
}


.main {
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
}