@import "styles/mixins.scss";


.root {
  @include container();
  min-height: $footer-height;
  composes: d-flex flex-column position-relative justify-content-center from global;
  width: 100vw;
  overflow-x: hidden;

  &,
  & a {
    color: #FFF;
  }
}


.background {
  width: 250%;
  height: calc(100% + 48px);
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  background-image: url(~assets/svg/footer.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  composes: position-absolute from global;
}