@import "styles/variables.scss";



/**
  NOTE: Both dropdownToggle & dropdownMenu
  are repeated in 'LocaleDropdown' component styles
  it needs to be refactored.
**/
.toggle {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  padding: 0;
  background: none;
  border: none;

  img {
    height: 40px;
    width: auto;

    @media (min-width: $breakpoint-md) {
      height: 45px;
    }
  }

  &:after {
    display: none;
  }
}

.menu {
  width: calc(48px + 2rem);

  &:global(.dropdown-menu) {
    position: absolute;
    min-width: auto;
    top: calc(100% + #{$spacing});
  }

  #{$rtl} &:global(.dropdown-menu) {
    left: 0;
    right: auto;
  }

  svg {
    width: 38px;
    height: auto;
  }

}

.menuItem {
  padding: 0.25rem 1rem;

  &:focus {
    outline: none;
  }
}