/*
 Bootstrap rtl overrides
*/
body[dir="rtl"] {
  .form-check {
    &-input {
      position: unset;
      margin-left: 0.75rem;
    }
  }

}