@import "styles/variables.scss";

.root {
  height: 100%;
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator {
  @media (max-width: $breakpoint-sm) {
    transform: translateY(-150%) scale(5);
  }

  transform: translateY(-150%) scale(10);
}

svg:not(:root) {
  overflow: hidden;
}

.indicator svg polyline {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.back {
  stroke: rgba(157, 157, 157, 0.4);
}

.front {
  stroke: $color-primary;
  stroke-dasharray: 12, 36;
  stroke-dashoffset: 46;
  animation: dash 1s linear infinite;
}

@keyframes dash {
  62.5% {
    opacity: 0.7;
  }

  to {
    stroke-dashoffset: 0;
  }
}