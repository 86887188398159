@import "styles/variables.scss";

.root {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  z-index: 9999;
  background-color: white;


  @media (min-width: $breakpoint-sm) {
    width: 100%;
    max-width: 400px;
  }
}


.header {
  color: $color-primary--dark;
}