@import "styles/mixins.scss";

.root {
  background: #FFF;
  height: 56px + $spacing * 2;
  flex-shrink: 0;

  @media (min-width: $breakpoint-md) {
    height: 68 + $spacing * 2;
    padding-top: $spacing * 2;
    padding-bottom: $spacing * 2;
  }

  @include container();
  @include shadow(1);
}

.innerWrapper {
  display: flex;
  align-items: center;
  composes: flex-row flex-grow-1 justify-content-end from global;
}

.svgContainer {
  & svg {
    height: 40px;
    width: auto;

    @media (min-width: $breakpoint-md) {
      height: 48px;
    }
  }
}

.brand {
  padding: 0;
  composes: svgContainer;
}


/**
  NOTE: Both dropdownToggle & dropdownMenu
  are repeated in 'LocaleDropdown' component styles
  it needs to be refactored.
**/
.dropdownToggle {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  padding: 0;
  background: none;
  border: none;
  composes: svgContainer;
  margin-left: 1rem;

  &:after {
    display: none;
  }
}

.dropdownMenu {
  &:global(.dropdown-menu) {
    position: absolute;
    min-width: auto;
    top: calc(100% + #{$spacing});
  }

  #{$rtl} &:global(.dropdown-menu) {
    left: 0;
    right: auto;
  }

  svg {
    width: 38px;
    height: auto;
  }

  :global(.dropdown-item):focus {
    outline: none;
  }
}

.hamburger {
  border: none;
}


.desktopMenu {
  padding: 0;
  margin: 0;
  composes: svgContainer;

  & .menuItem {
    list-style: none;
    margin-left: 16px;
    position: relative;

    :global .nav-link {
      padding: 0;
    }
  }

  & :global {
    a.active:after {
      content: " ";
      height: 8px;
      width: 100%;
      position: absolute;
      background: $color-primary;
      bottom: -$spacing * 1.5;
      left: 0;
      border-radius: 14px;
    }


    // NOTE: This is just a quick fix for the
    // react bootstrap router active state
    & a.active[data-rb-event-key="/logout"] {
      &:after {
        display: none;
      }
    }
  }


  & svg {
    height: 38px;
    width: auto;

    @media (min-width: $breakpoint-md) {
      height: 48px;
    }
  }
}

.menuItem {
  composes: d-inline-block from global;

  :global a[data-link-name="home"]::after {
    background: #1A9888;
  }

  :global a[data-link-name="profile"]::after {
    background: #FD7717;
  }

  :global a[data-link-name="videos"]::after {
    background: #01D1B7;
  }
}